import classnames from 'classnames';
import { FC } from 'react';
import { connect } from 'react-redux';
import { openChildInput, openInput } from '../../../actions/baseActions';
import FadeTransition from './FadeTransition';

type BaseTooltipErrorProps = {
	items: string[] | number[];
	openedChildInput?: string | null;
	openedInput?: string | null;
	name?: string | null;
	selected?: string | null | number;
	openInput: (name: string | null) => void;
	openChildInput: (name: string | null) => void;
	onSelect: (item: string | number) => void;
};

const MobileOptionsList: FC<BaseTooltipErrorProps> = (props) => {
	const { items, openedChildInput, name, selected, openedInput, onSelect, openInput, openChildInput } = props;

	const handleSelect = (item: string | number) => {
		onSelect(item);
		openChildInput(null);
		openInput('family-type-options');
	};

	return (
		<FadeTransition timeout={500} in={openedChildInput === name && openedInput === 'mobile-select-box'}>
			<div className="mobile-options">
				{items.map((item, key) => {
					return (
						<div key={key} onClick={() => handleSelect(item)} className="option">
							<span
								className={classnames('label', {
									selected: selected === item,
								})}
							/>{' '}
							{item}
						</div>
					);
				})}
			</div>
		</FadeTransition>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	openedChildInput: state.base.openedChildInput,
	openedInput: state.base.openedInput,
});
export default connect(mapStateToProps, { openChildInput, openInput })(MobileOptionsList);
