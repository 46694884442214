import Trans from 'app/components/Common/Trans';
import classnames from 'classnames';
import { FC } from 'react';
import { ItemType } from './InputDropDown';

type TabBarSelectProps = {
	items: ItemType[];
	selectedItem?: ItemType;
	onSelect: (item: ItemType) => void;
};
const TabBarSelect: FC<TabBarSelectProps> = (props) => {
	const { onSelect, selectedItem, items } = props;
	return (
		<div className="d-xl-none d-block basic-drop-down-tab-view">
			{items.map((item, key) => {
				return (
					<div
						onClick={() => onSelect(item)}
						key={key}
						className={classnames('item', {
							selected: !!(selectedItem && Object.keys(selectedItem).length && selectedItem.value === item.value),
						})}
					>
						{<Trans i18nKey={item.label} />}
					</div>
				);
			})}
		</div>
	);
};
export default TabBarSelect;
