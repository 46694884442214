import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { openChildInput, openInput } from '../../../actions/baseActions';
import FadeTransition from './FadeTransition';

type SelectBoxProps = {
	openedChildInput?: string | null;
	openedInput?: string | null;
	name?: string | null;
	items: string[] | number[];
	label: string;
	selected: string | number;
	onSelect: (item: string | number) => void;
	openInput: (name?: string | null) => void;
	openChildInput: (name?: string | null) => void;
};
type SelectBoxState = {
	open: boolean;
};
class SelectBox extends React.Component<SelectBoxProps, SelectBoxState> {
	constructor(props: SelectBoxProps) {
		super(props);
		this.state = {
			open: false,
		};
	}

	open = () => {
		const w = window.innerWidth;
		if (w < 575) {
			this.props.openInput('mobile-select-box');
		}
		this.setState(
			{
				open: w > 575,
			},
			() => {
				this.props.openChildInput(this.props.name);
			}
		);
	};
	onSelect = (value: string | number) => {
		this.props.onSelect(value);
		this.props.openChildInput(null);
	};

	render() {
		const { items, selected, openedChildInput, name } = this.props;
		const onSelect = this.onSelect;
		return (
			<div className="select-box">
				<div className="label">{this.props.label}</div>

				<div className="selected-area">
					<FadeTransition timeout={100} in={name === openedChildInput && this.state.open}>
						<div style={{ position: 'relative' }}>
							<div className="options">
								{items.map((item, key) => {
									return (
										<div
											key={key}
											onClick={() => onSelect(item)}
											className={classnames('option', {
												selected: item === selected,
											})}
										>
											{item}
										</div>
									);
								})}
							</div>
						</div>
					</FadeTransition>
					<div className="info" onClick={this.open}>
						<div className="selected">{selected}</div>
						<div className="icon">
							<i className="icon-arrow" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	openedChildInput: state.base.openedChildInput,
});
export default connect(mapStateToProps, { openChildInput, openInput })(SelectBox);
