import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyImage from '../Common/CompanyImage';

export const companies = [
	'/images/companies/booking.png?v1.1',
	'/images/companies/expedia.png?v1.1',
	'/images/companies/hotels-com.png?v1.1',
	'/images/companies/priceline.png?v1.1',
	'/images/companies/hotwire.png?v1.1',
	'/images/companies/kayak.png?v1.1',
	'/images/companies/trivago.png?v1.1',
	'/images/companies/travelocity.png?v1.1',
];
type CompaniesProps = {
	useHeadline?: boolean;
	description?: string;
};
const Companies: FC<CompaniesProps> = (props) => {
	const key = 'main.hotels.brandsDescription';
	const { t } = useTranslation();
	const defaultDescription = t(key);
	const actualDescription = props.description || defaultDescription;

	return (
		<div className="text-center" id="companies">
			{props.useHeadline ? (
				<h2 className="description">{actualDescription}</h2>
			) : (
				<div className="description">{actualDescription}</div>
			)}

			<ul>
				{companies.map((companyImage, key) => {
					return (
						<li key={key}>
							<CompanyImage src={companyImage} />
						</li>
					);
				})}
			</ul>
		</div>
	);
};
export default Companies;
