import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

interface TranslateStringProps {
	i18nKey: string;
	variables?: Record<string, string>;
}

const TranslateString: FC<TranslateStringProps> = (props) => {
	const { variables, i18nKey } = props;
	const { t } = useTranslation();
	return <span dangerouslySetInnerHTML={{ __html: t(i18nKey, { ...variables }) }} />;
};
export default memo(TranslateString);
