import ButtonWithBlueBorder from 'app/components/Common/ButtonWithBlueBorder';
import FadeTransition from 'app/components/Common/FadeTransition';
import MobileOptionsList from 'app/components/Common/MobileOptionsList';
import SelectBox from 'app/components/Common/SelectBox';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { openChildInput, openInput } from '../../../actions/baseActions';
import { addRoom, updateRooms } from '../../../actions/hotelActions';

const adults = [1, 2, 3, 4, 5, 6];
const children = [0, 1, 2, 3, 4, 5, 6];
type Rooms = {
	adults: number;
	children: number;
}[];
type FamilyTypeOptionsProps = {
	description?: string;
	openedChildInput?: string;
	openedInput?: string;
	rooms: Rooms;
	openChildInput: (name?: string | null) => void;
	openInput: (name?: string | null) => void;
	updateRooms: (rooms: Rooms) => void;
	addRoom: () => void;
};

const FamilyTypeOptions: FC<FamilyTypeOptionsProps> = (props) => {
	const { openedInput, openedChildInput, openChildInput, openInput, updateRooms, addRoom } = props;
	const { t } = useTranslation();

	const removeRoom = (key: number) => {
		let rooms = props.rooms;
		if (rooms.length === 1) {
			return;
		}
		delete rooms[key];
		rooms = rooms.filter((item) => {
			return item !== undefined;
		});
		updateRooms(rooms);
		openChildInput(null);
	};

	const onSelectAdults = (key: number, value: any) => {
		const rooms = props.rooms;
		rooms[key].adults = value;
		updateRooms(rooms);
	};

	const onSelectChildren = (key: number, value: any) => {
		const rooms = props.rooms;
		rooms[key].children = value;
		updateRooms(rooms);
	};

	const onClickContainer = (e: React.MouseEvent<HTMLDivElement>) => {
		const target = e.target as HTMLElement;

		if (!target.classList.contains('selected')) {
			openChildInput();
		}
	};

	const onClickBodyHighlight = () => {
		if (openedChildInput) {
			openChildInput();
			openInput('family-type-options');
		}
	};

	return (
		<div id="family-type-options" onClick={onClickContainer}>
			{openedInput === 'mobile-select-box' ? (
				<div
					style={{
						zIndex: 99999,
					}}
					onClick={onClickBodyHighlight}
					id="body-highlight"
				/>
			) : null}
			{props.rooms.map((item, key) => {
				return (
					<div key={key} className="room row">
						<div className="col-sm-3 p-0 col-12 ">
							<div className="room-name-area mb-2">
								<div className="name">
									{t('keywords.room')} {key + 1}
								</div>
								<div className="remove-icon d-sm-none">
									<FadeTransition timeout={200} in={props.rooms.length > 1}>
										<div onClick={() => removeRoom(key)} className="remove-icon-mobile">
											<i className="icon-cancel-circled" />
										</div>
									</FadeTransition>
								</div>
							</div>
							<div style={{ position: 'relative' }}>
								<MobileOptionsList
									name={'adults-select-box-' + key}
									selected={item.adults}
									onSelect={(value) => onSelectAdults(key, value)}
									items={adults}
								/>
								<MobileOptionsList
									name={'children-select-box-' + key}
									selected={item.children}
									onSelect={(value) => onSelectChildren(key, value)}
									items={children}
								/>
							</div>
						</div>
						<div className="col-sm-4 p-0 col-12">
							<SelectBox
								name={'adults-select-box-' + key}
								selected={item.adults}
								onSelect={(value) => onSelectAdults(key, value)}
								items={adults}
								label={t('keywords.adults')}
							/>
						</div>
						<div className="col-sm-4  p-0 col-12">
							<SelectBox
								name={'children-select-box-' + key}
								selected={item.children}
								onSelect={(value) => onSelectChildren(key, value)}
								items={children}
								label={t('keywords.children')}
							/>
						</div>

						<div className="remove-icon d-none d-sm-block col-sm-1" onClick={() => removeRoom(key)}>
							<FadeTransition timeout={200} in={props.rooms.length > 1}>
								<i className="icon-cancel-circled" />
							</FadeTransition>
						</div>
					</div>
				);
			})}
			<div id="actions">
				<ButtonWithBlueBorder onClick={addRoom} name={t('keywords.addRoom')} />
				<ButtonWithBlueBorder onClick={() => openInput(null)} name={t('keywords.confirm')} />
			</div>
		</div>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	rooms: state.hotel.rooms,
	openedInput: state.base.openedInput,
	openedChildInput: state.base.openedChildInput,
	random: state.hotel.random,
});
export default connect(mapStateToProps, { addRoom, openInput, updateRooms, openChildInput })(FamilyTypeOptions);
