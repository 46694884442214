import React, { FC } from 'react';
import { Button } from 'react-bootstrap';

type ButtonWithBlueBorderProps = {
	onClick: (event: React.MouseEvent<HTMLElement>) => void;
	icon?: string;
	name?: string;
};

const ButtonWithBlueBorder: FC<ButtonWithBlueBorderProps> = (props) => {
	const { onClick, icon, name } = props;
	return (
		<Button onClick={onClick} variant="outline-primary" className="btn-blue-border">
			{icon ? (
				<div className="icon">
					<i className={icon} />
				</div>
			) : null}
			<div className="flex-1">{name}</div>
		</Button>
	);
};

export default ButtonWithBlueBorder;
