import classnames from 'classnames';
import { addDays, isAfter, parseISO, startOfDay } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';
import { setCheckInDate, setCheckOutDate } from '../../../actions/hotelActions';
import Helper from '../../modules/Helper';
import DatePicker from '../Common/DatePicker';
import FadeTransition from '../Common/FadeTransition';

type CheckOutDateProps = {
	checkOutDate: Date;
	checkInDate: Date;
	name: string;
	openedInput?: string;
	autoJump?: boolean;
	preventSetInputBoxClassName?: boolean;
	lng: string;
	setCheckInDate: (date: Date) => void;
	setCheckOutDate: (date: Date) => void;
	openInput: (name?: string | null) => void;
};
type CheckOutDateState = {
	in: boolean;
};
class CheckOutDate extends React.Component<CheckOutDateProps, CheckOutDateState> {
	constructor(props: CheckOutDateProps) {
		super(props);
		this.state = {
			in: false,
		};
	}
	onSelectDate = (to: Date) => {
		let from = this.props.checkInDate;
		if (from === null) {
			from = new Date();
		} else {
			from = parseISO(from.toISOString());
		}
		if (to === null) {
			to = addDays(startOfDay(from), 1);
		} else {
			to = parseISO(to.toISOString());
		}

		if (isAfter(from, to)) {
			from = to;
		}

		this.props.setCheckInDate(from);
		this.props.setCheckOutDate(to);
		this.openCalendar(true);

		const w = window.innerWidth;
		if (w < 700) {
			Helper.doScrolling('#filter-form', 500);
		}
	};

	openCalendar = (close?: boolean) => {
		close = close || false;
		close = Helper.isBoolean(close) && close;
		this.setState(
			{
				in: close ? false : !this.state.in,
			},
			() => {
				if (close) {
					if (this.props.autoJump) {
						// this.props.openInput("room-type")
						this.props.openInput(null);
					} else {
						this.props.openInput(null);
					}
					return;
				}
				if (this.state.in) {
					this.props.openInput(this.props.name);
				} else if (this.props.autoJump) {
					// this.props.openInput("room-type")
					this.props.openInput(null);
				} else {
					this.props.openInput(null);
				}
			}
		);
	};

	componentDidUpdate(prevProps: CheckOutDateProps) {
		if (this.props.openedInput !== prevProps.openedInput) {
			if (Helper.isEmpty(this.props.openedInput)) {
				this.setState({
					in: false,
				});
			}
		}
	}

	render() {
		const { checkOutDate, name, openedInput, preventSetInputBoxClassName, checkInDate, lng } = this.props;
		const locale = lng === 'en' ? 'en-GB' : lng;

		return (
			<div
				id={name}
				className={classnames('flex-1', {
					'open-input': openedInput === name,
					'input-box': !preventSetInputBoxClassName,
				})}
			>
				<div
					className={classnames('input-control justify-content-between filled', {
						focused: openedInput === name,
					})}
					aria-label={checkOutDate.toLocaleString(locale, {
						weekday: 'short',
						month: 'short',
						day: 'numeric',
					})}
				>
					<div className="d-flex align-items-center flex-1" onClick={() => this.openCalendar()}>
						<i className="icon-date-arr" />
						<div className="selected">
							{checkOutDate.toLocaleString(locale, {
								weekday: 'short',
								month: 'short',
								day: 'numeric',
							})}
						</div>
					</div>
				</div>
				<div style={{ position: 'relative' }}>
					<FadeTransition timeout={100} in={openedInput === name}>
						<DatePicker
							before={addDays(checkInDate, 1)}
							selected={checkOutDate as Date}
							onSelectDate={this.onSelectDate}
						/>
					</FadeTransition>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	checkInDate: state.hotel.checkInDate,
	checkOutDate: state.hotel.checkOutDate,
	openedInput: state.base.openedInput,
	autoJump: state.base.autoJump,
	lng: state.base.lng,
});

export default connect(mapStateToProps, { setCheckInDate, setCheckOutDate, openInput })(CheckOutDate);
