import FamilyTypeOptions from 'app/components/Hotels/FamilyTypeOptions';
import { FC } from 'react';
import { createPortal } from 'react-dom';

interface FamilyTypeOptionsModalProps {
	openedInput: string | undefined | null;
	portalRef: any;
}

const FamilyTypeOptionsModal: FC<FamilyTypeOptionsModalProps> = (props) => {
	const { openedInput, portalRef } = props;
	const renderInput = openedInput === 'family-type-options' || openedInput === 'mobile-select-box';
	return portalRef.current ? createPortal(renderInput && <FamilyTypeOptions />, portalRef.current) : null;
};

export default FamilyTypeOptionsModal;
