export const zipcityParams = {
    en: {
        precision: 7,
        search_fields: {
            name: {},
            zip: {},
        },
        result_fields: {
            country: {
                raw: {},
            },
            city: {
                raw: {},
            },
            name: {
                raw: {},
            },
            state: {
                raw: {},
            },
            city_id: {
                raw: {},
            },

        },
        sort: [
            {
                _score: "desc",
            },
        ],
    },
}