import { searchByHotelIdInElastic } from 'app/api/hotels';
import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { openInput, setCityByCode, setLocationByIATACode } from '../../../actions/baseActions';
import { selectHotelDestination } from '../../../actions/hotelActions';
import { HOTEL_SET_DESTINATION } from '../../../actions/types';
import Helper from '../../modules/Helper';
import AirportSelect from '../Common/AirportSelect';
import { IAirportItem } from '../Common/AirportSelect/Item';

type HotelDestinationProps = {
	name: string;
	autoJump?: boolean;
	preventSetInputBoxClassName?: boolean;
	hotelDestination?: IAirportItem;
	openInput: (name?: string | null) => void;
	setCityByCode: (code: string, type: string) => void;
	selectHotelDestination: (location: IAirportItem | object) => void;
	setLocationByIATACode: (code: string, type: string) => void;
};

const HotelDestination: FC<HotelDestinationProps> = (props) => {
	useEffect(() => {
		setTimeout(() => {
			const parsed = Helper.parseQueryString(window.location.search, true);
			if (Helper.isEmpty(props.selectHotelDestination)) {
				document.getElementById(props.name + '-input')?.focus();
				// @ts-ignore
				document.getElementById(props.name + '-input')?.select();
			}
			if (parsed.f && parsed.f === 'cz' && parsed.hid) {
				searchHotel(parsed.hid.toString());
			}
			if (parsed.destcode) {
				const parsedDestCode = parsed.destcode.toString();
				if (Helper.isNumeric(parsed.destcode)) {
					props.setCityByCode(parsedDestCode, HOTEL_SET_DESTINATION);
				} else if (Helper.hasNumber(parsed.destcode)) {
					props.setCityByCode(parsedDestCode, HOTEL_SET_DESTINATION);
				} else if (parsed.destcode.length === 3) {
					props.setLocationByIATACode(parsedDestCode.toUpperCase(), HOTEL_SET_DESTINATION);
				}
			}
		}, 1);
	}, []);

	const searchHotel = async (hid: string) => {
		props.selectHotelDestination(await searchByHotelIdInElastic(hid));
	};

	useEffect(() => {
		if (props.hotelDestination) {
			(document.getElementById(props.name + '-input') as HTMLInputElement).disabled = true;
			(document.getElementById(props.name + '-input') as HTMLInputElement).disabled = false;
		}
	}, [props.hotelDestination, props.name]);

	const onSelect = (location: IAirportItem | object) => {
		props.selectHotelDestination(location);
		if (props.autoJump) {
			props.openInput('check-in-date');
		} else {
			props.openInput(null);
		}
	};

	return (
		<AirportSelect
			mobileHeading={Helper.trans('main.hotels.goingTo')}
			// i18nValidationDropDownKey={'validation.cars.pickUpLocationSelectFromDropDown'}
			preventSetInputBoxClassName={props.preventSetInputBoxClassName}
			icon="/images/hotels/marker-icon.svg"
			placeholder={Helper.trans('main.hotels.inputPlaceholder')}
			selected={props.hotelDestination}
			name={props.name}
			onSelect={onSelect}
		/>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	hotelDestination: state.hotel.hotelDestination,
	autoJump: state.base.autoJump,
});

export default connect(mapStateToProps, {
	selectHotelDestination,
	openInput,
	setLocationByIATACode,
	setCityByCode,
})(HotelDestination);
