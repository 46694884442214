import classnames from 'classnames';
import { addDays, getUnixTime, isAfter, isSameDay, parseISO, startOfDay } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';
import { setCheckInDate, setCheckOutDate } from '../../../actions/hotelActions';
import DatePicker from '../Common/DatePicker';
import FadeTransition from '../Common/FadeTransition';

type CheckInDateProps = {
	checkOutDate: Date;
	checkInDate: Date;
	name: string;
	openedInput?: string;
	autoJump?: boolean;
	preventSetInputBoxClassName?: boolean;
	lng: string;
	setCheckInDate: (date: Date) => void;
	setCheckOutDate: (date: Date) => void;
	openInput: (name?: string | null) => void;
};
type CheckInDateState = {
	in: boolean;
};
class CheckInDate extends React.Component<CheckInDateProps, CheckInDateState> {
	constructor(props: CheckInDateProps) {
		super(props);
		this.state = {
			in: false,
		};
	}

	onSelectDate = (from: Date) => {
		let to = this.props.checkOutDate;
		if (from === null) {
			from = new Date();
		} else {
			from = parseISO(from.toISOString());
		}
		if (to === null) {
			to = addDays(startOfDay(from), 1);
		} else {
			to = parseISO(to.toISOString());
		}
		const fromDate = getUnixTime(from);
		const toDate = getUnixTime(to);

		if (isSameDay(from, to)) {
			to = addDays(from, 1);
		}

		if (isAfter(fromDate, toDate)) {
			to = addDays(from, 1);
		}
		this.props.setCheckInDate(from);
		this.props.setCheckOutDate(to);

		this.openCalendar(true);
	};

	openCalendar = (openToDateCalendar?: boolean) => {
		openToDateCalendar = openToDateCalendar || false;
		this.setState(
			{
				in: !this.state.in,
			},
			() => {
				if (this.state.in) {
					this.props.openInput(this.props.name);
				} else if (openToDateCalendar === true) {
					if (this.props.autoJump) {
						this.props.openInput('check-out-date');
					} else {
						this.props.openInput();
					}
				}
			}
		);
	};

	componentDidUpdate(prevProps: CheckInDateProps) {
		if (this.props.openedInput !== prevProps.openedInput) {
			if (!this.props.openedInput) {
				this.setState({
					in: false,
				});
			} else if (this.props.openedInput === this.props.name) {
				this.setState({
					in: true,
				});
			}
		}
	}

	render() {
		const { checkInDate, openedInput, name, preventSetInputBoxClassName, lng } = this.props;
		const locale = lng === 'en' ? 'en-GB' : lng;

		return (
			<div
				id={name}
				className={classnames('flex-1', {
					'open-input': openedInput === name,
					'input-box': !preventSetInputBoxClassName,
				})}
			>
				<div
					className={classnames('input-control justify-content-between filled', {
						focused: openedInput === name,
					})}
					aria-label={checkInDate.toLocaleString(locale, {
						weekday: 'short',
						month: 'short',
						day: 'numeric',
					})}
				>
					<div className="d-flex align-items-center flex-1" onClick={() => this.openCalendar()}>
						<i className="icon-date" />
						<div className="selected">
							{checkInDate.toLocaleString(locale, {
								weekday: 'short',
								month: 'short',
								day: 'numeric',
							})}
						</div>
					</div>
				</div>
				<div style={{ position: 'relative' }}>
					<FadeTransition timeout={500} in={openedInput === name}>
						<DatePicker before={new Date()} selected={checkInDate as Date} onSelectDate={this.onSelectDate} />
					</FadeTransition>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	checkInDate: state.hotel.checkInDate,
	checkOutDate: state.hotel.checkOutDate,
	openedInput: state.base.openedInput,
	autoJump: state.base.autoJump,
	lng: state.base.lng,
});

export default connect(mapStateToProps, { setCheckInDate, openInput, setCheckOutDate })(CheckInDate);
