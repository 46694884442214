import React from 'react';
import Image from "next/image";
import {jumpToSearchInput} from "../../../actions/baseActions";
import {connect} from "react-redux";

export interface ICompanyImageProps {
    src: string
    jumpToSearchInput: () => void

}

const CompanyImage: React.FC<ICompanyImageProps> = (props) => {
    return (
        <div onClick={() => props.jumpToSearchInput()} style={{position: 'relative', overflow: 'hidden'}}><img
            width={120} height={75} style={{width:'120px',height:"75px"}} src={props.src} alt={props.src.split('/')[3].split('.')[0]}/></div>
    )
}

export default connect(null, {jumpToSearchInput})(CompanyImage);
