import classnames from 'classnames';
import { format } from 'date-fns';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
	setClosedPriceAlertSignUpModal,
	setPriceAlertEmail,
	togglePriceAlertSignUpModal,
} from '../../../actions/baseActions';
import { doSearchHotels, doSearchPartners } from '../../../actions/hotelActions';
import Helper from '../../modules/Helper';
import { IAirportItem } from '../Common/AirportSelect/Item';
import Checkbox from '../Common/Checkbox';
import FadeTransition from '../Common/FadeTransition';

type ModalSignUpProps = {
	priceAlertShowModalSignUp: boolean;
	email: string;
	enabledVert: string;
	hotelDestination: IAirportItem;
	checkOutDate?: Date;
	checkInDate?: Date;
	lng: string;
	setPriceAlertEmail: (email: string) => void;
	setClosedPriceAlertSignUpModal: (toggle: boolean) => void;
	doSearchHotels: () => void;
	doSearchPartners: (isKcForm: boolean) => void;
};

const ModalSignUp: FC<ModalSignUpProps> = (props) => {
	const [showModal, setShowModal] = useState(false);
	const [isInvalidEmail, setIsInvalidEmail] = useState(false);
	const [isCheckedNewsLetter, setIsCheckedNewsLetter] = useState(true);
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		setShowModal(props.priceAlertShowModalSignUp);
	}, [props.priceAlertShowModalSignUp]);

	const handleClose = () => {
		setShowModal(false);
	};

	const doSearch = () => {
		if (!Helper.isValidEmail(props.email) && props.email.length) {
			setIsInvalidEmail(true);
			setIsButtonDisabled(true);
			return;
		}
		setIsInvalidEmail(false);
		setIsButtonDisabled(false);
		handleClose();
		props.setClosedPriceAlertSignUpModal(true);
		switch (props.enabledVert) {
			case 'hotels':
				if (Helper.isKayakCompareFlowFront() || Helper.isKayakCompareFlowBack()) {
					const isKcForm = true;
					props.doSearchPartners(isKcForm);
					break;
				}
				props.doSearchHotels();
				break;
			default:
				break;
		}
	};
	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		props.setPriceAlertEmail(e.target.value);
		setIsButtonDisabled(!Helper.isValidEmail(e.target.value));
	};

	useEffect(() => {
		const closeBtn = document.getElementsByClassName('close');
		if (closeBtn.length) {
			closeBtn[0].addEventListener('click', handleClose);
		}
	}, []);

	const getSelectedLocationHotel = () => {
		if (Helper.isEmpty(props.hotelDestination) || !showModal) {
			return;
		}
		return Helper.getSelectedLocation(props.hotelDestination, 200);
	};

	const getHotelsDataInfo = () => {
		const { checkInDate, lng } = props;
		return (
			<div className="data-info row align-items-center">
				<div className="location me-2 col-md-8" id="price-alert-location-info">
					{getSelectedLocationHotel()}
				</div>
				<div className="date col-xl">
					{format(checkInDate as Date, 'eee, MMM d', { locale: Helper.getLocaleByCode(lng) })}
				</div>
			</div>
		);
	};

	return (
		<Modal
			backdrop={true}
			id="price-alert-modal-signup"
			size="sm"
			centered
			onEscapeKeyDown={close}
			onHide={doSearch}
			show={showModal}
		>
			<div onClick={() => doSearch()} id="exit-icon">
				<img src="/images/exit.svg" alt="" />
			</div>
			<Modal.Body>
				<h3 className="heading">{t('priceAlert.modalSignUp.header')}</h3>
				<div className="title">{t(`priceAlert.modalSignUp.${props.enabledVert}.title`)}</div>
				<div
					className={classnames('sign-up-form', props.enabledVert, {
						'mt-5': props.enabledVert === 'hotels',
					})}
				>
					{props.enabledVert === 'hotels' ? getHotelsDataInfo() : ''}

					<div className="form-group" style={{ position: 'relative' }}>
						<FadeTransition timeout={1003} in={isInvalidEmail}>
							<div className="tooltip-error">{t('validation.priceAlert.email')}</div>
						</FadeTransition>
						<div style={{ position: 'relative' }}>
							<div style={{ position: 'absolute', left: '10px', top: '13px' }} className="icon">
								<img src="/images/email-icon.svg" alt="" />
							</div>
							<input
								onChange={onChange}
								value={props.email}
								autoComplete="email"
								placeholder={t('keywords.emailAddress')}
								type="email"
								className="form-control"
							/>
						</div>
					</div>
					<div className="form-group">
						<Button
							style={{ width: '100%' }}
							variant="dark"
							onClick={doSearch}
							className="btn btn-block btn-black"
							disabled={!props.email.length || isButtonDisabled}
						>
							{t('keywords.signUpAndSearch')}
						</Button>
					</div>
				</div>
				<div>
					<Checkbox
						onChange={() => {
							setIsCheckedNewsLetter(!isCheckedNewsLetter);
						}}
						checked={isCheckedNewsLetter}
						label={t('priceAlert.modalSignUp.emailNewsletterSubscription')}
						id="price-alert-news-letter"
					/>
				</div>
				<br />
				<div>
					<p className="desc">{t('priceAlert.modalSignUp.bottomDescription')}</p>
				</div>
			</Modal.Body>
		</Modal>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	priceAlertShowModalSignUp: state.base.priceAlertShowModalSignUp,
	email: state.base.priceAlertEmail,
	hotelDestination: state.hotel.hotelDestination,
	checkInDate: state.hotel.checkInDate,
	checkOutDate: state.hotel.checkOutDate,
	enabledVert: state.base.enabledVert,
	lng: state.base.lng,
});
export default connect(mapStateToProps, {
	togglePriceAlertSignUpModal,
	setPriceAlertEmail,
	doSearchHotels,
	doSearchPartners,
	setClosedPriceAlertSignUpModal,
})(ModalSignUp);
