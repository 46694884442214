import FamilyTypeOptionsModal from 'app/components/Hotels/FamilyTypeOptionsModal';
import { roomTypes } from 'app/modules/Constants';
import { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';
import { setRoomType, updateRooms } from '../../../actions/hotelActions';
import BasicDropDown from '../Common/BasicDropDown';
import { ItemType } from '../Common/InputDropDown';

type RoomsProps = {
	selectedRoomType: ItemType;
	name: string;
	openedInput?: string;
	roomsPortalRef: RefObject<HTMLDivElement>;
	rooms: {
		adults: number;
		children: number;
	}[];
	setRoomType: (type: ItemType) => void;
	updateRooms: (rooms: any) => void;
	openInput: (name: string | null) => void;
};

const Rooms: FC<RoomsProps> = (props) => {
	const { selectedRoomType, name, roomsPortalRef } = props;
	const { t } = useTranslation();

	const onSelect = (item: ItemType) => {
		props.setRoomType(item);

		switch (item.value) {
			case 'one-person':
				props.updateRooms([{ adults: 1, children: 0 }]);
				break;
			case 'two-person':
				props.updateRooms([{ adults: 2, children: 0 }]);
				break;
			default:
				break;
		}
		if (item.value === 'family') {
			props.openInput('family-type-options');
		} else {
			props.openInput(null);
		}
	};

	const getCustomSelectedTextForFamily = () => {
		const rooms = props.rooms;
		let guests = 0;
		for (let i = 0; i < rooms.length; i++) {
			guests += rooms[i].adults + rooms[i].children;
		}
		let roomsName = t('keywords.rooms');
		const guestsName = t('keywords.guests');
		if (rooms.length === 1) {
			roomsName = t('keywords.room');
		}
		return `${rooms.length} ${roomsName}, ${guests} ${guestsName}`;
	};

	return (
		<div id="rooms">
			<BasicDropDown
				icon={selectedRoomType.icon}
				mainInput
				showOnSmallDevices
				showCustomSelectedText={selectedRoomType.value === 'family'}
				customSelectedText={getCustomSelectedTextForFamily()}
				onSelect={onSelect}
				name={name}
				selected={selectedRoomType}
				placeholder="keywords.rooms"
				preventCloseInput
				items={roomTypes}
			/>

			<FamilyTypeOptionsModal openedInput={props.openedInput} portalRef={roomsPortalRef} />
		</div>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	selectedRoomType: state.hotel.selectedRoomType,
	openedInput: state.base.openedInput,
	rooms: state.hotel.rooms,
	random: state.hotel.random,
});
export default connect(mapStateToProps, { setRoomType, openInput, updateRooms })(Rooms);
