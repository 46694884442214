import React, {ChangeEvent} from 'react';

type CheckboxProps = {
    id: string,
    label?: string,
    checked: boolean,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
};
type CheckboxState = {};

class Checkbox extends React.PureComponent<CheckboxProps, CheckboxState> {
    render() {
        return (
            <div className="form-check custom-checkbox position-relative">
                <label htmlFor={this.props.id} className="form-check-label">
                    <input checked={this.props.checked} name={this.props.id} id={this.props.id}
                        // @ts-ignore
                           onChange={this.props.onChange ? this.props.onChange : null} type="checkbox"
                           className="form-check-input" value=""/><span
                    className="checkmark"/> {this.props.label ?? ''}
                </label>
            </div>
        );
    }
}

export default Checkbox;
