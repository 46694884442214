import React, { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

interface IFadeTransitionProps {
	in?: boolean;
	timeout: number;
	children: ReactNode;
}
const FadeTransition: React.FC<IFadeTransitionProps> = (props) => {
	return (
		<CSSTransition in={props.in} timeout={props.timeout} unmountOnExit={true} classNames="fade">
			{props.children}
		</CSSTransition>
	);
};
export default React.memo(FadeTransition);
